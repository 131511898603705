import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column;
  gap: 1rem;
  padding-top: 70px;

  h1 {
    margin-bottom: 4rem;
  }

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const NotFoundPage = () => (
  <Layout seo="404 not found - Nu var du visst ute och cykla!">
    <Container>
      <div>
        <h1>404 - NOT FOUND</h1>
        <h3>Nu var du visst ute och cykla!</h3>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
